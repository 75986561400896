<template>
  <div>
    <div class="information"
      style="width:95%;margin:0 auto;">
      <van-list :loading="loading"
        :finished="finished"
        offset="0"
        @load="onLoad">
        <div class="center"
          v-for="item in NoticeList"
          :key="item.index">
          <div @click="gotoUrl(item.Id)">
            <!-- <div class="centerTitle van-ellipsis">{{ item.Title }}</div> -->
            <div class="Content">
              <div style="display: inline-block"
                class="ContentA">
                <img v-if="item.CoverImgUrl"
                  :src="item.CoverImgUrl"
                  alt="" />
              </div>
              <div style="display: inline-block"
                class="ContentB">
                <span class="van-multi-ellipsis--l3"
                  style="font-size:16px;color:black;">{{ item.Title }}</span>
                <br />
                <span class="centertime">{{ item.AddTime }}</span>
              </div>
            </div>
          </div>
        </div>
      </van-list>
      <!-- <van-empty description="暂无养生知识"
        v-if="!NoticeList.length" /> -->
      <div v-if="hidden"
        class="hidden">───── 没有更多数据了 ─────</div>
    </div>
  </div>
</template>
<script>
import { WeGetArticleList } from "@/api/Pension";
export default {
  data () {
    return {
      NoticeList: [],
      listfrom: {
        page: 0,
        limit: 10,
        type: "01"
      },
      newlist: [],
      loading: false, //加载
      hidden: true, //没有更多数据了
      finished: false, //数据完全加载结束为 true
    }
  },
  created () {
    // this.getNoticeList()
  },
  methods: {
    // 通知消息
    getNoticeList () {
      WeGetArticleList(this.listfrom).then((res) => {
        this.loading = false;
        if (res.data.code == 0) {
          this.total = res.data.count;
          if (res.data.count == 0) {
            this.finished = true;
            this.NoticeList = [];
          } else if (res.data.count == 1) {
            this.NoticeList = res.data.data;
          } else {
            if (res.data.data.length == 0) {
              this.finished = true;
            } else {
              this.newlist = this.NoticeList;
              this.NoticeList = this.newlist.concat(res.data.data);
              console.log(this.NoticeList);
            }
          }
          // this.NoticeList = res.data.data;
        } else {

        }

      });
    },
    gotoUrl: function (id) {
      this.$router.push("/pension/zsXiangQing/" + id);
    },
    // 下拉刷新
    onLoad () {
      console.log(123);
      this.listfrom.page = this.listfrom.page + 1;
      this.getNoticeList();
    },
  }
};
</script>
<style scoped>
.hidden {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  margin: 10px 0px 25px 0px;
}
</style>